import createEmotionCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { hydrate } from "react-dom";
import createEmotionCacheCSS from "./server/emotions_cache";

const hydrateDocument = () => {
  const emotionCache = createEmotionCacheCSS();

  hydrate(
    <StrictMode>
      <CacheProvider value={emotionCache}>
        <RemixBrowser />
      </CacheProvider>
    </StrictMode>,
    document
  );
};

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(hydrateDocument);
} else {
  setTimeout(hydrateDocument, 1);
}
